const STATUS_CODE ={
                    'VALIDATION_ERROR':422,
                    'SUCCESS':200,
                    'REDIRECT':302,
                    'UNAUTHORIZED':401,
                    "SUCCESS_TYPE":'SUCCESS',
                    'ERROR_TYPE':'ERROR'
                };
const MESSAGE ={
    'INVALID_ERROR':'The given data was invalid.'
}

const customeErrors = function($data){
    let $customError = {}
            for(var k in $data) {
                $customError[k]=$data[k][0];
             }
    return $customError;
}
export default{STATUS_CODE,MESSAGE,customeErrors}