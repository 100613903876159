import code from '../code';
import route from '../route';

export default {
   async getStates(){
        let url = window.URL+route.share.GET_STATE;
        let that = this;
        return axios.get(url)
         .then(function($datas){
            if($datas.status == code.STATUS_CODE.SUCCESS)
            {
                return Promise.resolve($datas.data)
            }
         })
         .catch(function($errors){
           let $response = $errors.response;
           let $status = $response.status;
            if($status == code.STATUS_CODE.VALIDATION_ERROR){      
             return Promise.reject({'code':code.STATUS_CODE.VALIDATION_ERROR,"msg":$response.data.message,"errors":code.customeErrors($response.data.errors)||{}});
            }
         })
    }, 

    async getCity(url){
      url = window.URL+route.share.GET_CITY+'/'+url;
      let that = this;
      return axios.get(url)
       .then(function($datas){
          if($datas.status == code.STATUS_CODE.SUCCESS)
          {
              return Promise.resolve($datas.data)
          }
       })
       .catch(function($errors){
         let $response = $errors.response;
         let $status = $response.status;
          if($status == code.STATUS_CODE.VALIDATION_ERROR){      
           return Promise.reject({'code':code.STATUS_CODE.VALIDATION_ERROR,"msg":$response.data.message,"errors":code.customeErrors($response.data.errors)||{}});
          }
       })
  }
}