import address from "../../../api/share/address";

const state = ()=>({
    state:[],
    city:[]
});

// getters
const getters = {
    getState:(state)=>{
        return state.state;
    },
    getCity:(state)=>{
        return state.city;
    }
}

// actions
const actions = {
    // Get All state form api
    getAllState({commit}){
        address.getStates()
        .then(function($data){
            if($data.state)
            commit('setState',$data.data);  /**set value in mututer commit('muteterName',$value) */
        })
        .catch(function($error){
        });
    },
     // Get All City form api
    getAllCity({commit},selected){
        address.getCity(selected)
        .then(function($data){
            if($data.state)
            commit('setCity',$data.data);  /**set value in mututer commit('muteterName',$value) */
        })
        .catch(function($error){
        });
    }
}

// mutations
const mutations = {
    setState(state, $data){
        state.state = $data;
      },
    setCity(state, $data){
            state.city = $data;
        },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }