import InputForm from './ui-kit/InputFormGroup.vue';
import Checkbox from './ui-kit/CheckboxComponent.vue';

const component = {
    components:{
                    'input-form':InputForm,
                    'check-box':Checkbox,   
                }
}

export default {
    component
}