<template>

    <div class="checkbox-row">
        <label class="container-row "> {{label}}
        <input type="checkbox" v-bind:class="{'is-invalid':_getErrorMsg(errors)!=''}" v-on:click="_change" v-bind:name="name" v-bind="$attrs">
        <span class="checkmark"></span>
         <error-msg v-if="_getErrorMsg(errors)!='' && showError" v-bind:msg="_getErrorMsg(errors)"></error-msg>
        </label>
    </div>


</template>
<script>
    import ErrorComponent from './ErrorComponent.vue';
    export default {
        name:"Checkbox",
        props:{

          errors:{
              type:Object,
              required:false
          },
            label:{
                type:String,
                required: true
                },
            name:{
                type:String,
                required:true
            },
            showError:{
                type:Boolean,
                required:false
            }

        },
        components:{
           'error-msg':ErrorComponent
        },
        mounted(){
                },
        methods:{
              _getErrorMsg(errors){
              return  errors[this.name] || ''
            },
             _change($event){

                            this.$emit('input',{
                                                    type:this.name,
                                                    value: $event.target.value || '',
                                                    flag:$event.target.checked
                                                });

                            //
                        }
        }
    }
</script>
