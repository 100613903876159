<template>
   <div v-if="message.length" class="form-group">
        <div class="invalid-message">
            <ul>
                <li v-for="(item, index) in message" :key="index" >{{item}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name:"AlertBox",
        props:{
            type:{
                type:String,
                required: false
                },
            message:{
                type:Array,
                required:true,
                default:[]
            },
        },
        mounted(){
                },
        methods:{
          
            _getErrorMsg(errors){
              return  errors[this.name] || ''
            },
           
        }
    }
</script>
