<template>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title" id="exampleModalLongTitle">
            <h3>Create Your Account</h3>
            <p>Three quick steps to get you started!</p>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
            <div v-if="isDone" class="modal-login-form">
                <p>{{msg}}</p>
            </div>
            <div v-if="!isDone" class="form-tab-slid">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item ">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                        <span class="tabs-number">1</span>
                        <span class="tabs-text">1. Personal Info</span>
                    </a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        <span class="tabs-number">2</span>
                        <span class="tabs-text">2. Business Info</span>
                    </a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                        <span class="tabs-number">3</span>
                        <span class="tabs-text">3. Payment Info</span>
                    </a>
                    </li>
                </ul>
            </div>

            <div v-if="!isDone"  class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <profile-info-component v-on:action="action" ></profile-info-component>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <business-info-component v-on:action="action" v-bind:previous="previous"></business-info-component>
                </div>
                <div class="tab-pane fade " id="contact" role="tabpanel" aria-labelledby="contact-tab">
                     <p v-if="false">First payment won't to be processed untill after the first month from creating your account.</p>
                    <payment-info-component  v-on:action="action" v-bind:previous="previous"></payment-info-component>
                </div>
            </div>
        </div>
            <!--div class="modal-footer">
                <p>If you list a CPA Practice or Client for Sale on the CPA EXCHANGE within the first free month, the next six months are free.
                <a v-bind:href="url">More...</a>
                </p>
                <img v-bind:src="img" alt=""/>
            </div-->
        </div>
    </div>
</template>

<script>
    import PersonalInfo from './PersonalInfoComponent.vue';
    import BusinessInfo from './BusinessInfoComponent.vue';
    import PaymentInfo from './PaymentInfoComponent.vue';
    import address from '../../api/share/address';
    import code from '../../api/code';
    import { mapGetters, mapState } from 'vuex';
    import Route from '../../api/route';

    export default {
        data(){
            return {
                previous:{},
                isDone:false,
                msg:'',
                img:URL+'/images/modal-foot-img.svg',
                url:URL+Route.auth.PRICE
            }
        },
      components:{
          'profile-info-component': PersonalInfo,
          'business-info-component': BusinessInfo,
          'payment-info-component': PaymentInfo
      },
      mounted() {

      },
       created () {
             this.$store.dispatch('address/getAllState');
        },
      methods:{
          action:function(value){
              if(value.currentTab==4){
                this.isDone = true;
               this.msg=value.msg
                }
                else{
                        this.previous = value;
                        $('#'+value.current).removeClass('show active')
                        $('#'+value.next).addClass('show active')
                        $('#'+value.current+'-tab').removeClass('active').addClass('success_form')
                        $('#'+value.next+'-tab').addClass('active')
                    }
        }
      }
    }
</script>
