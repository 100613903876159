<template>

    <div class="form-group">
         <select v-on:change="change" v-bind:name="name" v-bind:value="value"  v-bind:class="{'form-control':true, 'is-invalid':_getErrorMsg(errors)!=''}" v-bind="$attrs" >
            <option value="">Select</option>
            <option v-for="item in options" :key="item.value" v-bind:value="item.value">{{item.label}}</option>
         </select>
        <label class="floating-label">{{label}}</label>
        <error-msg v-bind:msg="_getErrorMsg(errors)"></error-msg>
    </div>
</template>

<script>
import ErrorComponent from './ErrorComponent.vue';
    export default {
        name:"SelectForm",
        props:{
             errors:{
               type:Object,
               required:false,
               default:{}
           },
           options:{
               type:Array,
               default:[]
           },

            label:{
                type:String,
                required: true
                },
            name:{
                type:String,
                required:true
            },
            value:{
                type:String,
                required:false
            }

        },
        components:{
           'error-msg':ErrorComponent
        },
        mounted(){
                },
        methods:{

            _getErrorMsg(errors){
              return  errors[this.name] || ''
            },
            change($event){
                this.$emit('input',{
                    type:this.name,
                    value: $event.target.value || ''
                })
            }
        }
    }
</script>
