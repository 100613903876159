<template>
<div class="modal-login-form">
    <alert-box v-bind:type="type" v-bind:message="msg"></alert-box>
    <input-form  v-if="false"  v-bind:errors="errors"  v-bind:value="name_bill" v-on:input="_input" type="text"  v-bind:name="'name_bill'" id="name_bill" label="Full name on Bill" autocomplete="off" required="true" autofocus="true"></input-form>

    <input-form v-bind:errors="errors" v-bind:isCard="true" v-if="false" v-bind:value="number" v-on:input="_input" type="text"  v-bind:name="'number'" id="number" label="Card Number" autocomplete="off" required="true" autofocus="true"></input-form>


    <div v-if="false" class="form-row">
        <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 item">
            <input-form v-bind:errors="errors"  v-bind:value="cvv" v-on:input="_input" type="text"  v-bind:name="'cvv'" id="cvv" label="CVV" autocomplete="off" required="true" autofocus="true"></input-form>

        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 item">
            <select-form type="text" v-bind:errors="errors"  v-bind:value="exp" v-on:input="_input"  v-bind:name="'exp'" id="exp" label="Month" required="true" v-bind:options="exp_options"></select-form>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 item">
            <select-form type="text" v-bind:errors="errors"  v-bind:value="expy" v-on:input="_input"  v-bind:name="'expy'" id="expy" label="Year" required="true" v-bind:options="expy_options"></select-form>
        </div>
        </div>
    </div>

    <div class="billing-address">
        <span>Billing Address</span>
    </div>
     <input-form v-bind:errors="errors"  v-bind:value="address_bill" v-on:input="_input" type="text"  v-bind:name="'address_bill'" id="address_bill" label="Street Address" autocomplete="off" required="true" autofocus="true"></input-form>

    <div class="form-row">
        <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 item">
             <select-form type="text" v-bind:errors="errors"  v-bind:value="state_bill" v-on:input="_input"  v-bind:name="'state_bill'" id="state_bill" label="State" required="true" v-bind:options="state_options"></select-form>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 item">
             <input-form v-bind:errors="errors"  v-bind:value="code_bill" v-on:input="_input" type="text"  v-bind:name="'code_bill'" id="code_bill" label="Zipcode" autocomplete="off" required="true" autofocus="true"></input-form>
        </div>
        </div>
    </div>
     <select-form type="text" v-bind:errors="errors"  v-bind:value="city_bill" v-on:input="_input"  v-bind:name="'city_bill'" id="city_bill" label="City" required="true" v-bind:options="city_options"></select-form>

    <div class="modal-login-btn">
        <div v-if="isLoading"  class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <button v-if="!isLoading"  v-on:click="_submitClick()" class="primary btnNext" type="submit" form="" value="">Continue</button>
    </div>
</div>
</template>

<script>
/***<input-form v-bind:errors="errors"  v-bind:value="exp" v-on:input="_input" type="text"  v-bind:name="'exp'" id="exp" label="Exp. M" autocomplete="off" required="true" autofocus="true"></input-form> */
    import InputForm from '../share/ui-kit/InputFormGroup.vue';
    import Checkbox from '../share/ui-kit/CheckboxComponent.vue';
    import SelectForm from '../share/ui-kit/SelectFormGroup';
    import register from '../../api/auth/register';
    import code from '../../api/code';
    import AlertBox from '../share/ui-kit/AlertBoxComponent';
    import { mapGetters, mapState,mapActions } from 'vuex'

    export default {
        name:"PaymentInfo",
         computed:{

            state_options(){
                return this.$store.state.address.state||[];
            },
            city_options(){
                return this.$store.state.address.city||[];
            },

        },
         watch: {
      	previous: function(newVal, oldVal) {
               // watch it
          if('businessInfo' in newVal)
          {
              this.city_bill = newVal.businessInfo.city;
              this.state_bill = newVal.businessInfo.state;
                this.address_bill = newVal.businessInfo.address;
                  this.code_bill = newVal.businessInfo.code;
          }
        }
      },
         props:{previous:Object},
         data(){
                  return {
                    isLoading:false,
                    type:'',
                    errors: {},
                    name_bill: '',
                    number:'',
                    cvv: '',
                    address_bill: '',
                    city_bill:'',
                    state_bill:'',
                    code_bill:'',
                    exp:'',
                    expy:'',
                    msg:[],
                    type:'',
                    exp_options:[],
                    expy_options:[]
                    }
                },
        mounted() {
            this.getMonth();
            this.getYears();
        },

        components:{
            'input-form':InputForm,
            'select-form':SelectForm,
            'alert-box':AlertBox
        },
        methods:{
              ...mapActions('address', {
                   getCity: 'getAllCity'
             }),
             loading:function(value){
                 this.isLoading = value;
            },
             _submitClick:async function(){
                 this.loading(true)

                                        let e={
                                                ...this.previous,
                                                "paymentInfo":{
                                                    name_bill: this.name_bill,
                                                    number: this.number,
                                                    cvv:this.cvv,
                                                    address_bill: this.address_bill,
                                                    city_bill:this.city_bill,
                                                    state_bill:this.state_bill,
                                                    code_bill:this.code_bill,
                                                    exp:this.exp,
                                                    expy:this.expy,
                                                    exp_options:[]
                                                    },
                                                "currentTab":3,
                                                }

                                    let that = this;
                                  await  register.register(e)
                                      .then(function(success){
                                          that.loading(false)
                                          if(success.status == 'DONE')
                                          {
                                               that.$emit('action',{"currentTab":4,"msg":success.data[0],next:'contact',current:'profile',prervious:'home'});
                                          }
                                      })
                                      .catch(function(error){
                                           that.loading(false)
                                          if(code.STATUS_CODE.VALIDATION_ERROR == error.code){
                                              that.errors = error.errors;
                                              that.msg = [error.msg];
                                              that.type =code.STATUS_CODE.ERROR_TYPE
                                            }
                                      });
                                    },
             // Take value from InptComponent
            _input:function($e){
                switch($e.type)
                {
                    case "name_bill":
                                this.name_bill = $e.value;
                                break;
                    case "number":
                                this.number = $e.value;
                                break;
                    case "address_bill":
                                this.address_bill = $e.value;
                                break;
                    case "city_bill":
                                this.city_bill = $e.value;
                                break;
                    case "state_bill":
                                this.state_bill = $e.value;
                                this.getCity($e.value);
                                break;
                    case "code_bill":
                                this.code_bill = $e.value;
                                break;
                    case "exp":
                                this.exp = $e.value;
                                break;
                    case "expy":
                                this.expy = $e.value;
                                break;
                    case "cvv":
                            this.cvv = $e.value;

                }
            },
            getMonth:function(){
                this.exp_options =[
                    {value:1,label:'Jan'},
                    {value:2,label:'Feb'},
                    {value:3,label:'Mar'},
                    {value:4,label:'Apr'},
                    {value:5,label:'May'},
                    {value:6,label:'Jun'},
                    {value:7,label:'Jul'},
                    {value:8,label:'Aug'},
                    {value:9,label:'Sep'},
                    {value:10,label:'Oct'},
                    {value:11,label:'Nov'},
                    {value:12,label:'Dec'},
                ];
            },
            getYears:function(){

                let tempArray=[];
                var d = new Date();
                var year = d.getFullYear();
               for(let i = 0; i <= 15;i++)
               {
                   tempArray.push(parseInt(year)+i);
               }
              this.expy_options = tempArray.map(function(value,index){
                  return {value:value,label:value};
              });

            }
        }
    }
</script>
