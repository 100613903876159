<template>
   <div class="modal-login-form">
        <alert-box v-bind:type="type" v-bind:message="msg"></alert-box>
         <input-form v-bind:errors="errors"  v-bind:value="firm_name" v-on:input="_input" type="text"  v-bind:name="'firm_name'" id="firm_name" label="Firm Name" autocomplete="off" required="true" autofocus="true"></input-form>

         <input-form v-bind:errors="errors"  v-bind:value="phone" v-on:input="_input" type="text"  v-bind:name="'phone'" id="phone" label="Phone No" autocomplete="off" required="true" autofocus="true"></input-form>

        <input-form v-bind:errors="errors"  v-bind:value="certificate" v-on:input="_input" type="text"  v-bind:name="'certificate'" id="certificate" label="CPA License or Certificate No." autocomplete="off" required="true" autofocus="true"></input-form>

        <input-form v-bind:errors="errors"  v-bind:value="address" v-on:input="_input" type="text"  v-bind:name="'address'" id="address" label="Street Address" autocomplete="off" required="true" autofocus="true"></input-form>
        <div class="form-row">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 item">
                    <select-form type="text" v-bind:errors="errors"  v-bind:value="state" v-on:input="_input"  v-bind:name="'state'" id="state" label="State" required="true" v-bind:options="state_options"></select-form>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 item">
                    <input-form v-bind:errors="errors"  v-bind:value="code" v-on:input="_input" type="text"  v-bind:name="'code'" id="code" label="Zipcode" autocomplete="off" required="true" autofocus="true"></input-form>
                </div>
            </div>
        </div>
         <select-form type="text" v-bind:errors="errors"  v-bind:value="city" v-on:input="_input"  v-bind:name="'city'" id="city" label="City" required="true" v-bind:options="city_options"></select-form>

        <div class="modal-login-btn">
             <div v-if="isLoading"  class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <button v-if="!isLoading" class="primary btnNext" v-on:click="_submitClick()"  type="submit" form="" value="">Continue</button>
        </div>
    </div>
</template>

<script>
    import InputForm from '../share/ui-kit/InputFormGroup.vue';
    import Checkbox from '../share/ui-kit/CheckboxComponent.vue';
     import SelectForm from '../share/ui-kit/SelectFormGroup';

    import AlertBox from '../share/ui-kit/AlertBoxComponent';

    import register from '../../api/auth/register';
    import code from '../../api/code';
    import { mapGetters, mapState,mapActions } from 'vuex'

    export default {
        name:"BusinessInfo",
        computed:{
            state_options(){
                return this.$store.state.address.state||[];
            },
            city_options(){
                return this.$store.state.address.city||[];
            }
        },
         props:{previous:Object},
         data(){
                  return {
                    isLoading:false,
                    type:'',
                    errors: {},
                    firm_name:'',
                    phone:'',
                    certificate: '',
                    address: '',
                    city:'',
                    state:'',
                    code:'',
                    msg:[],
                    }
                },
        mounted() {
        },
        components:{
            'input-form':InputForm,
             'select-form':SelectForm,
              'alert-box':AlertBox
        },
        methods:{
             ...mapActions('address', {
                   getCity: 'getAllCity'
             }),
              loading:function(value){
                 this.isLoading = value;
            },
             _submitClick:async function(){
                 this.loading(true);
                                let e={
                                        ...this.previous,
                                        "businessInfo":{
                                            firm_name: this.firm_name,
                                            phone: this.phone,
                                            certificate: this.certificate,
                                            address: this.address,
                                            city:this.city,
                                            state:this.state,
                                            code:this.code
                                            },
                                        "currentTab":2,
                                        }
                                    let that = this;
                                  await  register.register(e)
                                      .then(function(success){
                                          that.loading(false);
                                          if(success.status == 'NEXT')
                                          {
                                              that.$emit('action',{...e,next:'contact',current:'profile',prervious:'home'});
                                          }
                                      })
                                      .catch(function(error){
                                          that.loading(false);
                                          if(code.STATUS_CODE.VALIDATION_ERROR == error.code){
                                              that.errors = error.errors;
                                               that.msg = [error.msg];
                                            }
                                      });
                                    },
             // Take value from InptComponent
            _input:function($e){
                switch($e.type)
                {
                    case "firm_name":
                                this.firm_name = $e.value;
                                break;
                    case "phone":
                                this.phone = $e.value;
                                break;
                    case "certificate":
                                this.certificate = $e.value;
                                break;
                    case "address":
                                this.address = $e.value;
                                break;
                    case "city":
                                this.city = $e.value;
                                break;
                    case "state":
                                this.state = $e.value;
                                this.getCity($e.value);
                                break;
                    case "code":
                                this.code = $e.value;
                                break;
                }
            },
        }
    }
</script>
