import route from '../route';
import code from '../code';

const _user ={
    email:'',
    password:'',
    isLogin:'',
    redirect:null
}

export default {
    async login($data){
        let url = window.URL+route.auth.LOGIN;
        return axios.post(url,$data)
        .then(function($data){
            $response =$data.data;
            window.location.href = $response.data;
            return Promise.resolve($datas.data)
        })
        .catch(function($errors){
          let $response = $errors.response || '';
          let $status = $response.status || '';
          if($status == code.STATUS_CODE.REDIRECT)
          {
          }
          else if($status == code.STATUS_CODE.VALIDATION_ERROR){      
            return Promise.reject({'code':code.STATUS_CODE.VALIDATION_ERROR,"msg":[$response.data.message],"errors":code.customeErrors($response.data.errors)||{}});
           }
           else if($status == code.STATUS_CODE.UNAUTHORIZED)
           {
            return Promise.reject({'code':code.STATUS_CODE.UNAUTHORIZED,"msg":[$response.data.message],"errors":code.customeErrors($response.data.errors)||{}});
           }
           else{
            window.location.href = route.auth.DASHBOARD;
           }
        })
    }
}