<template>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title" id="exampleModalLongTitle">
                <h3>Welcome to the CPA EXCHANGE!</h3>
                <p>Log in or Sign up and begin interacting with others in the CPA community.</p>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
            <alert-box v-bind:message="message||[]"></alert-box>
            <div class="modal-login-form">

            <input-form v-bind:value="email" v-on:input="_input" type="text" v-bind:errors="errors" v-bind:name="'email'" id="email" label="Email" autocomplete="off" required="true" autofocus="true"></input-form>

            <input-form v-bind:value="password" v-on:input="_input" type="password" v-bind:errors="errors" v-bind:name="'password'" id="password" label="Password" autocomplete="off" required="true" autofocus="true"></input-form>

            <div class="modal-login-btn">
                <button class="primary" v-on:click="_submitClick()" type="button" form="" value="">Log in</button>
                 <a v-on:click="forgotPassword()" href="javascript:void(0)">Forgot password</a>
            </div>
            </div>
        </div>
        <div class="modal-footer">
            <img src="images/login-bg.svg" alt=""/>
        </div>
        </div>
    </div>
</template>



<script>
    import InputForm from '../share/ui-kit/InputFormGroup.vue';
    import login from '../../api/auth/login';
    import code from '../../api/code';
    import AlertBoxComponentVue from '../share/ui-kit/AlertBoxComponent.vue';

    export default {
        name:"LoginComponent",
         data(){
                  return {
                            errors: [],
                            email: 'nishnatraj656@gmail.com',
                            password: 'admin@123',
                            message:[]
                        }
                },
        mounted() {

        },
        components:{
            'input-form':InputForm,
            'alert-box':AlertBoxComponentVue
        },
        methods:{
            _submitClick:async function(){

                                        let e = {
                                                    email: this.email,
                                                    password: this.password
                                                }
                                    let that = this;
                                  await  login.login(e)
                                      .then(function(success){

                                      })
                                      .catch(function(errors){
                                          console.error("errors",errors);
                                          if(code.STATUS_CODE.VALIDATION_ERROR == errors.code){
                                              that.errors = errors.errors;
                                            }
                                            else
                                            {
                                                that.errors = errors.errors
                                                that.message = errors.msg
                                            }
                                      });
                                    },
            // Take value from InptComponent
            _input:function($e){
                switch($e.type)
                {
                    case "email":
                                this.email = $e.value;
                                break;
                    case "password":
                                this.password = $e.value;
                                break;
                }
            },
            forgotPassword:function(){
             $('#login_modal').modal('hide');
             $('#forgot_password_modal').modal('show');
            }

        }
    }
</script>