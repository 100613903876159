<template>
    <span class="invalid-feedback">{{msg}}</span>
</template>

<script>
    export default {
        name:'ErrorComponent',
        props:{
            msg:String
        },
        mounted() {
           
        }
    }
</script>
