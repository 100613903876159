<template>
    <div class="form-group">
         <input  v-bind:name="name" v-model="valueDat" v-bind:class="{'form-control':true, 'is-invalid':_getErrorMsg(errors)!='','input-has-value':value.length!=0,'cc-number':isCard}" v-bind="$attrs" />
        <label class="floating-label">{{label}}</label>
         <error-msg v-if="_getErrorMsg(errors)!=''" v-bind:msg="_getErrorMsg(errors)"></error-msg>
    </div>
</template>

<script>
import ErrorComponent from './ErrorComponent.vue';
    export default {

        name:"InputForm",
        props:{
           errors:{
               type:Object,
               required:false,
               default:{}
           },
            label:{
                type:String,
                required: true
                },
            name:{
                type:String,
                required:true
            },
            value:{
                type:String,
                required:false
            },
            isCard:{
                type:Boolean,
                required:false,
                default:false
            }

        },
        components:{
           'error-msg':ErrorComponent,
        },
        computed:{
             valueDat:{
               get:function(){
                    return this.value;
               },
               set:function(newValue)
               {
                     //if(this.isCard)
                     //{
                    //     newValue = newValue.replace(/\s/g, '') ;
                    // }
                   this.$emit('input',{
                        type:this.name,
                        value: newValue || ''
                    })
               }
           }
        },

        methods:{


            _getErrorMsg(errors){
              return  errors[this.name] || ''
            }
        }
    }
</script>
