const auth ={
    REGISTER:'register',
    // LOGIN:'login/front',
    DASHBOARD:'dashboard',
    PRICE:'page/price'
}


const share ={
    GET_STATE:'state',
    GET_CITY:'city'
}
export default {auth,share}
