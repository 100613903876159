import route from "../route";
import code from "../code";

const _register = {
                   "personalInfo":{
                                    name:'',
                                    email:'',
                                    password:'',
                                    password_confirmation:'',
                                    agree:''
                                    }
                    }

export default {
    checkform(va){

    },
    async register(value){
       let url = window.URL+route.auth.REGISTER;
       let that = this;
       return axios.post(url,value)
        .then(function($datas){
           if($datas.status == code.STATUS_CODE.SUCCESS)
           {
               return Promise.resolve($datas.data);
           }
        })
        .catch(function($errors){
          let $response = $errors.response;
          let $status = $response.status;
           if($status == code.STATUS_CODE.VALIDATION_ERROR){      
            return Promise.reject({'code':code.STATUS_CODE.VALIDATION_ERROR,"msg":$response.data.message,"errors":code.customeErrors($response.data.errors)||{}});
           }
        })
    },
    // async businessInfo(value){
    //     let url = window.URL+route.auth.REGISTER;
    //     let that = this;
    //     return axios.post(url,value)
    //      .then(function($datas){
    //         if($datas.status == code.STATUS_CODE.SUCCESS)
    //         {
    //             return Promise.resolve($datas.data)
    //         }
    //      })
    //      .catch(function($errors){
    //        let $response = $errors.response;
    //        let $status = $response.status;
    //         if($status == code.STATUS_CODE.VALIDATION_ERROR){      
    //          return Promise.reject({'code':code.STATUS_CODE.VALIDATION_ERROR,"msg":$response.data.message,"errors":code.customeErrors($response.data.errors)||{}});
    //         }
    //      })
    //  }, 
    thisThrows() {
        throw new Error("Thrown from thisThrows()");
    }

}

// ,{headers:{
//     'Content-Type': 'application/json',
//     'Accept':'application/json'
// }}