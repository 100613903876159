import Vue from 'vue';
import Vuex from 'vuex';
// import createLogger from '../../../src/plugins/logger';
import address from './modules/share/address';
import auth from './modules/auth/auth';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    address,
    auth
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
});