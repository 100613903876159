<template>
    <div class="modal-login-form">
        <alert-box v-bind:type="type"  v-bind:message="msg"></alert-box>
        <input-form  v-bind:errors="errors"  v-bind:value="name" v-on:input="_input" type="text"  v-bind:name="'name'" id="name" label="First and last name" autocomplete="off" required="true" autofocus="true"></input-form>

        <input-form  v-bind:errors="errors"  v-bind:value="email" v-on:input="_input" type="text"  v-bind:name="'email'" id="email" label="Email" autocomplete="off" required="true" autofocus="true"></input-form>

        <input-form  v-bind:errors="errors"  v-bind:value="password" v-on:input="_input" type="password"  v-bind:name="'password'" id="password" label="Password" autocomplete="off" required="true" autofocus="true"></input-form>

         <input-form  v-bind:errors="errors"  v-bind:value="cpassword" v-on:input="_input" type="password"  v-bind:name="'cpassword'" id="cpassword" label="Confirm Password" autocomplete="off" required="true" autofocus="true"></input-form>

        <check-box :showError="false" style="margin-bottom: 4px;" v-bind:errors="errors"  v-bind:value="'accountant'" v-on:input="_input" v-bind:label="'I certify that I am a U.S. Certified Public Accountant'" v-bind:name="'agree'"></check-box>

        <check-box :showError="false" style="margin-bottom: 4px;" v-bind:errors="errors"  v-bind:value="'association'" v-on:input="_input" v-bind:label="'I certify that I am an Enrolled Agent'" v-bind:name="'agree'"></check-box>

        <check-box :showError="false" style="margin-bottom: 4px;" v-bind:errors="errors"  v-bind:value="'consultant'" v-on:input="_input" v-bind:label="'I certify that I am a Licensed Chartered Accountant'" v-bind:name="'agree'"></check-box>

        <check-box :showError="true" style="margin-bottom: 4px;"  v-bind:errors="errors"  v-bind:value="'firm'" v-on:input="_input" v-bind:label="'I work at a Public Accounting Firm'" v-bind:name="'agree'"></check-box>

        <div class="modal-login-btn">
        <div v-if="isLoading"  class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <button v-if="!isLoading" class="primary btnNext" v-on:click="_submitClick()"  type="submit" form="" value="">Continue</button>
        </div>
    </div>
</template>

<script>
    // import component from '../share/components';
    import InputForm from '../share/ui-kit/InputFormGroup.vue';
    import Checkbox from '../share/ui-kit/CheckboxComponent';
    import register from '../../api/auth/register';
    import code from '../../api/code';

    import AlertBox from '../share/ui-kit/AlertBoxComponent';

    import components from '../share/components';

    export default {
        name:"PersonalInfo",
         data(){
                  return {
                    type:'',
                    errors:{},
                    name:'',
                    email: '',
                    password: '',
                    cpassword:'',
                    us_financial:false,
                    agree:[],
                    us_law:false,
                    us_firm:false,
                    msg:[],
                    isLoading:false
                    }
                },
        mounted() {
        },
        components:{
            'input-form':InputForm,
            'check-box':Checkbox,
            'alert-box':AlertBox
        },
        methods:{
            loading:function(value){
                 this.isLoading = value;
            },
            _submitClick:async function(){
                    this.loading(true);
                                        let e = {
                                                "currentTab":1,
                                                "personalInfo":{
                                                    name: this.name,
                                                    email: this.email,
                                                    password: this.password,
                                                    password_confirmation:this.cpassword,
                                                    agree:this.agree
                                                    }
                                                }
                                    let that = this;

                                  await  register.register(e)
                                      .then(function(success){
                                          that.loading(false);
                                          if(success.status == 'NEXT')
                                          {
                                            that.$emit('action',{...e,next:'profile',current:'home',prervious:null});
                                          }

                                      })
                                      .catch(function(error){
                                          that.loading(false);
                                          if(code.STATUS_CODE.VALIDATION_ERROR == error.code){
                                              that.errors = error.errors;
                                               that.msg = [error.msg];
                                            }

                                      });
                                    },
            // Take value from InptComponent
            _input:function($e){
                switch($e.type)
                {
                    case "name":
                                this.name = $e.value;
                                break;
                    case "email":
                                this.email = $e.value;
                                break;
                    case "password":
                                this.password = $e.value;
                                break;
                    case "cpassword":
                                this.cpassword = $e.value;
                                break;
                    case "agree":
                                if($e.flag)
                                {
                                    this.agree.push($e.value);
                                }
                                else
                                {
                                    const index = this.agree.indexOf($e.value);
                                    if (index > -1)
                                    {
                                        this.agree.splice(index, 1);
                                    }
                                }
                                break;


                }
            }

        }
    }
</script>
